import React, { useState } from 'react';
import AuthCard from 'components/AuthCard';
import CompanyName from 'components/SelectCompany';
import InputItem from 'components/InputItem';
import InviteCode from './InviteCode';
import styled from 'styled-components';
import { Button, Input, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import PageTracker from 'components/PageTracker';
import { withRouter, Link } from 'react-router-dom';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { RadioButtons } from 'ds';

import api from 'api';
import urls from 'urls';
import featureFlags from 'config/featureFlags';
import processError from 'utils/processError';
import validateEmail from 'utils/validateEmail';
import spacing from 'styles/layout/spacing';

// const CAPTCHA_ERROR = 'Cannot proceed now';
const COMPANY_MISSING_ERROR = 'Please select your company';
const EMAIL_FORMAT_ERROR = 'Please type a valid email address';
const EMAIL_MISSING_ERROR = 'Please type your work email';

const Error = styled(Typography.Text)`
  text-align: center;
`;

export const StyledButton = styled(Button)`
  margin-top: ${spacing.gutter.lg};
  width: 180px;
  align-self: center;
`;

const Prompt = styled.span`
  align-self: center;
  text-align: center;
  margin-bottom: ${(props) => (props.first ? '10px' : '0')};
`;

const StyledAnchor = styled.a`
  text-decoration: underline;
  :hover {
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  :hover {
    text-decoration: underline;
  }
`;

const countries = [
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Australia', value: 'AU' },
];

const navigateToNextPage = (history, email) => {
  history.push(`/signup?step=signup-confirmation&email=${email}`);
};

const SignupEntry = (props) => {
  const { history, tokenFromInviteLink, invitedUser } = props;

  // const { executeRecaptcha } = useGoogleReCaptcha();

  const isInvitedUser = !!tokenFromInviteLink;

  const [company, setCompany] = useState(null);
  const [email, setEmail] = useState(isInvitedUser ? invitedUser.username : '');
  const [showInviteCodeEntry, setInviteCodeEntryVisibility] = useState(
    !isInvitedUser
  );
  const [country, setCountry] = useState('NZ');
  const [companyError, setCompanyError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isNewCompanySelected, setNewCompanySelected] = useState(true);

  // for company input disable
  const [editingCompany, setEditingCompany] = useState(true);

  const onSelectCompany = (company) => {
    setCompany(company);
    setCompanyError('');
    setNewCompanySelected(true);
    setEditingCompany(false);
  };

  const onSubmit = async () => {
    setError('');
    if (!isNewCompanySelected) {
      setCompanyError(COMPANY_MISSING_ERROR);
      return;
    }
    let hasErrors = !!emailError;
    if (!email) {
      setEmailError(EMAIL_MISSING_ERROR);
      hasErrors = true;
    }
    if (!company) {
      setCompanyError(COMPANY_MISSING_ERROR);
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }
    setLoading(true);

    // const captchaToken = await executeRecaptcha('signup');
    // const isCaptchaVerified = await api.recaptcha.verify(captchaToken);
    // if (!isCaptchaVerified) {
    //   // ReCaptcha smells something fishy! Let's stop right here.
    //   setError(CAPTCHA_ERROR);
    //   setLoading(false);
    //   return;
    // }

    const payload =
      country === 'AU'
        ? {
            user: { username: email },
            company: {
              ...company,
              address: { country: 'AU', countryCode: 'AU' },
            },
          }
        : {
            user: { username: email },
            company: {
              ...company,
              address: { country: 'NZ', countryCode: 'NZ' },
            },
          };

    api.auth
      .signup(payload, tokenFromInviteLink || '')
      .then((response) => {
        const emailSent = response?.emailSent;
        if (isInvitedUser && !emailSent) {
          // Invited user, onboarding as a new business.
          // We should let the user straight away activate their account,
          // since email verification is not needed.
          history.push(`${urls.activate.name}&token=${tokenFromInviteLink}`);
        } else {
          // Go to the page that says verification email is sent.
          navigateToNextPage(history, email);
        }
      })
      .catch((error) => {
        const { status, errors, message } = processError(error);
        if (status === 409) {
          setEmailError(
            'This email address is already registered. Please use a different one.'
          );
        } else {
          if (errors && errors.length) {
            //eslint-disable-next-line
            errors.map((item) => {
              if (item.split(',')[0] === 'username') {
                setEmailError(EMAIL_FORMAT_ERROR);
              }
            });
          } else {
            setError(message);
          }
        }
        setLoading(false);
      });
  };

  const onEmailFieldBlur = (e) => {
    const { value } = e.target;
    if (value) {
      const isEmailValid = validateEmail(value);
      setEmailError(isEmailValid ? '' : EMAIL_FORMAT_ERROR);
    } else {
      setEmailError(EMAIL_MISSING_ERROR);
    }
  };

  const onEmailEntry = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeCountry = (e) => {
    setCompany(null);
    setCountry(e.target.value);
    setEditingCompany(true);
  };

  let message = '';
  if (isInvitedUser) {
    message = 'Confirm your details';
  } else {
    if (showInviteCodeEntry) {
      message = 'Enter Invite Code';
    } else {
      message = `Let's get started`;
    }
  }

  let subMessage = '';
  if (isInvitedUser) {
    subMessage = 'Please enter your details below to continue';
  } else {
    if (showInviteCodeEntry) {
      subMessage = 'Please enter your invite code to join Relay';
    } else {
      subMessage = 'Signing up for Relay is fast and free';
    }
  }

  return (
    <AuthCard message={message} subMessage={subMessage}>
      <PageTracker />
      {showInviteCodeEntry ? (
        <>
          <InviteCode
            setInviteCodeEntryVisibility={setInviteCodeEntryVisibility}
          />
          <Prompt first>
            To request access please send an email to{' '}
            <StyledAnchor
              href="mailto:hello@relay.ai"
              rel="noopener noreferrer"
              target="_blank"
            >
              hello@relay.ai
            </StyledAnchor>
          </Prompt>
        </>
      ) : (
        <>
          {featureFlags.australiaSignup && (
            <InputItem label="Business location">
              <RadioButtons
                options={countries}
                onChange={handleChangeCountry}
                value={country}
              />
            </InputItem>
          )}

          <InputItem
            label="Business name"
            error={
              companyError ||
              (!isNewCompanySelected && !company && COMPANY_MISSING_ERROR)
            }
          >
            <CompanyName
              key={country}
              countryCode={country}
              selectedCompanyName={company ? company.companyName : ''}
              onSelectCompany={onSelectCompany}
              onInput={() => {
                setNewCompanySelected(false);
              }}
              suffix={
                company &&
                !editingCompany && (
                  <CloseOutlined
                    onClick={() => {
                      setCompany(null);
                      setEditingCompany(true);
                    }}
                  />
                )
              }
              disabled={!editingCompany}
            />
          </InputItem>
          <InputItem label="Work email" error={emailError}>
            <Input
              name="email"
              label="Work email"
              type="email"
              placeholder="Enter your work email..."
              value={email}
              onChange={onEmailEntry}
              onBlur={onEmailFieldBlur}
              error={emailError}
              required={true}
              disabled={isInvitedUser}
              size="large"
            />
          </InputItem>

          {!!error && <Error type="danger">{error}</Error>}

          <StyledButton
            type="primary"
            onClick={onSubmit}
            loading={loading}
            size="large"
          >
            {isInvitedUser ? 'Continue' : 'Get Started'}
          </StyledButton>
        </>
      )}

      {isInvitedUser ? null : (
        <Prompt>
          Already have a Relay account?{' '}
          <StyledLink to="/login">Sign in</StyledLink>
        </Prompt>
      )}
    </AuthCard>
  );
};

export default withRouter(SignupEntry);
