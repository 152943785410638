import React, { useEffect, useState } from 'react';
import Container from '../Container';
import Footer from '../Footer';
import PrimaryButton from '../PrimaryButton';
import DateOfBirth from './DateOfBirth';
import IdentityVerification from './IdentityVerification';
import { AddressInput, InputItem, TitleSection } from 'components';
import NameEntry from './NameEntry';
import Pending from './Pending';
import styled from 'styled-components';
import { Button, Divider, Input, Typography, notification } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { withRouter } from 'react-router';

import api from 'api';
import getDesignation from 'utils/getDesignation';
import joinName from 'utils/joinName';
import processError from 'utils/processError';
import splitName from 'utils/splitName';
import urls from 'urls';

import User from 'types/User';
import { Address } from 'types/Address';
import {
  driversLicenceValidator,
  licenceValidatorResult,
} from 'utils/driversLicenceValidator/driversLicenceValidator';
import featureFlags from 'config/featureFlags';

const { Text } = Typography;
const { TextArea } = Input;

const year = '';
const month = '';
const day = '';

const NAME_MISSING_ERROR = 'Plases enter your full legal name';
const NAME_CONFIRMATION_ERROR =
  'Please confirm your changes by clicking Done button';
const DOB_ERROR = 'Please select complete date of birth';
const DL_VERSION_ERROR = `Please enter driver's licence version`;
const PASSPORT_ERROR = 'Please enter your passport number';
// const CAPTCHA_ERROR = 'Cannot proceed now';
const ADDRESS_MISSING_ERROR = 'Plases select an address from the suggestions';

const NotesContainer = styled.div`
  margin-top: 27px;
`;

type Props = {
  countryCode: Address['countryCode'];
  primaryUser?: User;
  token: string;
  setUser?: (user: User) => void;
  isLoggedIn?: boolean;
  location: { state: { user: User } };
  owner?: User;
  isLoggedInAsAdmin?: boolean;
  onCancel?: () => void;
  onDone?: () => void;
} & RouteComponentProps;

const UserVerification: React.FC<Props> = (props) => {
  const {
    countryCode,
    primaryUser,
    token,
    history,
    location,
    setUser,
    isLoggedIn,
    owner,
    isLoggedInAsAdmin,
    onCancel,
    onDone,
  } = props;
  const { executeRecaptcha } = useGoogleReCaptcha();

  const isPrimaryUser = !!primaryUser;
  const user: User = primaryUser || location.state.user || owner;

  const { firstName, middleName, lastName, roles, verificationStatus } = user;
  const [name, setName] = useState(
    joinName({ firstName, middleName, lastName })
  );
  const [originalName, setOriginalName] = useState(
    joinName({ firstName, middleName, lastName })
  );
  const [dateOfBirth, setDateOfBirth] = useState({ year, month, day });
  const [address, setAddress] = useState<Address | null>(null);
  const [verifyWith, setVerifyWith] = useState<'DRIVERS_LICENCE' | 'PASSPORT'>(
    'DRIVERS_LICENCE'
  );
  const [driversLicenceNumber, setDriversLicenseNumber] = useState('');
  const [driversLicenceVersion, setDriversLicenseVersion] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [passportExpiry, setPassportExpiry] = useState({ year, month, day });
  const [note, setNote] = useState('');

  const [error, setError] = useState('');
  const [nameError, setNameError] = useState('');
  const [dobError, setDobError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [dlNumberError, setDlNumberError] = useState('');
  const [dlVersionError, setDlVersionError] = useState('');
  const [passportNumberError, setPassportNumberError] = useState('');

  const [editingName, setEditingName] = useState(false);
  const [loading, setLoading] = useState(false);

  const isVerificationPending =
    isPrimaryUser && user.verificationStatus === 'VERIFICATION_PENDING';

  useEffect(() => {
    if (!user) {
      history.push(`${urls.verify.ownersList}&token=${token}`);
    } else {
      const {
        driversLicenceNumber,
        driversLicenceVersion,
        dateOfBirth,
        firstName,
        middleName,
        lastName,
      } = user;
      setName(joinName({ firstName, middleName, lastName }));
      setOriginalName(joinName({ firstName, middleName, lastName }));
      if (dateOfBirth) {
        const [year, month, day] = dateOfBirth.split('-');
        setDateOfBirth({ year, month, day });
      } else {
        setDateOfBirth({ year, month, day });
      }
      setDriversLicenseNumber(driversLicenceNumber || '');
      setDriversLicenseVersion(driversLicenceVersion || '');
    }
  }, [history, token, user]);

  // When user enters text in name field
  const onNameEntry = (name: string): void => {
    if (name) {
      setNameError('');
    }
    setName(name);
  };

  // When Done button on name field is clicked
  const onSubmitName = (): void => {
    if (name === originalName) {
      // There is no change in name. No need to call API.
      setEditingName(false);
      setNameError('');
      return;
    }
    const { firstName, middleName, lastName } = splitName(name);
    if (!firstName || !lastName) {
      setNameError(NAME_MISSING_ERROR);
      return;
    }
    const updatedUser = { ...user, firstName, middleName, lastName };
    api.user
      .put({ isLoggedInAsAdmin, token, user: updatedUser })
      .then(() => {
        setNameError('');
        setOriginalName(name);
        setEditingName(false);
      })
      .catch((error) => {
        const { message } = processError(error);
        setNameError(message);
      });
  };

  // When Verify button is clicked
  const onSubmit = async (): Promise<void> => {
    setNameError('');
    setDobError('');
    setDlNumberError('');
    setDlVersionError('');
    let hasErrors = false;
    const { firstName, middleName, lastName } = splitName(name);
    if (!firstName || !lastName) {
      setNameError(NAME_MISSING_ERROR);
      hasErrors = true;
    }
    if (editingName) {
      setNameError(NAME_CONFIRMATION_ERROR);
      hasErrors = true;
    }
    if (!dateOfBirth.year || !dateOfBirth.month || !dateOfBirth.day) {
      setDobError(DOB_ERROR);
      hasErrors = true;
    }
    if (countryCode === 'AU' && !address) {
      setAddressError(ADDRESS_MISSING_ERROR);
      hasErrors = true;
    }
    if (verifyWith === 'DRIVERS_LICENCE') {
      const licenseResult = driversLicenceValidator(driversLicenceNumber);
      if (!(licenseResult === licenceValidatorResult.Valid)) {
        setDlNumberError(licenseResult);
        hasErrors = true;
      }
      if (!driversLicenceVersion) {
        setDlVersionError(DL_VERSION_ERROR);
        hasErrors = true;
      }
    }
    if (verifyWith === 'PASSPORT' && !passportNumber) {
      setPassportNumberError(PASSPORT_ERROR);
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }

    setLoading(true);

    //let captchaToken = '';
    if (isPrimaryUser && executeRecaptcha) {
      // captchaToken = await executeRecaptcha('identity_verification');
      // const isCaptchaVerified = await api.recaptcha.verify(captchaToken);
      // if (!isCaptchaVerified) {
      //   // ReCaptcha smells something fishy! Let's stop right here.
      //   setError(CAPTCHA_ERROR);
      //   return;
      // }
    }

    let updatedUser = {
      ...user,
      address: countryCode === 'AU' ? address : user.address,
      firstName,
      middleName,
      lastName,
      dateOfBirth: `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`,
      verifyWith,
      note,
    };
    if (verifyWith === 'DRIVERS_LICENCE') {
      updatedUser = {
        ...updatedUser,
        driversLicenceNumber,
        driversLicenceVersion,
      };
    }

    // Update user with new values
    api.user
      .put({ isLoggedInAsAdmin, token, user: updatedUser })
      .then((user) => {
        if (isPrimaryUser) {
          setUser && setUser(user);
        }

        // Calling the API to try verification
        api.identity
          .verifyIdentity({
            countryCode,
            token,
            userId: isPrimaryUser ? null : user.id,
          })
          .then((response: User) => {
            // API returned 200. The user is now either in VERIFIED or VERIFICATION_FAILED state

            if (response.verificationStatus === 'VERIFIED') {
              if (isLoggedInAsAdmin) {
                notification.success({
                  message: `Verification is successful for ${name}`,
                });
                setLoading(false);
                onDone && onDone();
              } else if (isPrimaryUser) {
                history.replace(`${urls.verify.business}&token=${token}`);
              } else {
                history.replace(`${urls.verify.ownersList}`, {
                  lastOperatedOwnerId: response.id,
                });
              }
            } else {
              // Verification failed
              if (isLoggedInAsAdmin) {
                notification.error({
                  message: `Verification failed for ${name}`,
                });
                setLoading(false);
                onDone && onDone();
              } else if (isPrimaryUser) {
                history.replace(`${urls.verify.upload}&token=${token}`);
              } else {
                let next = `${urls.verify.ownerUpload}`;
                if (!isLoggedIn) {
                  next = `${next}&token=${token}`;
                }
                history.replace(next, { user: response, name });
              }
            }
          })
          .catch((error) => {
            const { message } = processError(error);
            setError(message);
            setLoading(false);
            if (isLoggedInAsAdmin) {
              notification.error({ message });
            }
          });
      })
      .catch((error) => {
        const { message } = processError(error);
        setError(message);
        setLoading(false);
        if (isLoggedInAsAdmin) {
          notification.error({ message });
        }
      });
  };

  const goBack = (): void => {
    history.go(-1);
  };

  let title = 'Verify your identity';
  if (!isPrimaryUser) {
    title = 'Verify identity of owner or director';
  }
  if (isVerificationPending) {
    title = 'Verification Pending';
  }
  if (isLoggedInAsAdmin) {
    title = '';
  }
  let subTitle = '';
  if (isPrimaryUser && !isVerificationPending) {
    subTitle =
      'Before we open your business account we need to confirm your identity. This helps us to keep your account safe.';
  }

  const handleSelectAddress = (address: Address | null) => {
    setAddress(address);
    setAddressError('');
  };

  return (
    <>
      <Container>
        <TitleSection title={title} subTitle={subTitle} />
        {isVerificationPending ? (
          <Pending />
        ) : (
          <>
            <NameEntry
              designation={getDesignation(roles)}
              editing={editingName}
              setEditing={setEditingName}
              error={nameError}
              isPrimaryUser={isPrimaryUser}
              isLoggedInAsAdmin={isLoggedInAsAdmin}
              name={name}
              onNameEntry={onNameEntry}
              onKeyPress={(e: { key: string }): void => {
                if (e.key === 'Enter') {
                  onSubmitName();
                }
              }}
              onSubmitName={onSubmitName}
              verificationStatus={verificationStatus}
            />
            <DateOfBirth
              dateOfBirth={dateOfBirth}
              setDateOfBirth={setDateOfBirth}
              dobError={dobError}
            />
            {featureFlags.addressForVerification && countryCode === 'AU' && (
              <AddressInput
                countryCode={countryCode}
                error={addressError}
                label="Residential address"
                onSelect={handleSelectAddress}
              />
            )}
            <IdentityVerification
              countryCode={countryCode || 'NZ'}
              driversLicenceNumber={driversLicenceNumber}
              setDriversLicenseNumber={setDriversLicenseNumber}
              driversLicenceVersion={driversLicenceVersion}
              setDriversLicenseVersion={setDriversLicenseVersion}
              passportNumber={passportNumber}
              setPassportNumber={setPassportNumber}
              passportExpiry={passportExpiry}
              setPassportExpiry={setPassportExpiry}
              verifyWith={verifyWith}
              setVerifyWith={setVerifyWith}
              dlNumberError={dlNumberError}
              dlVersionError={dlVersionError}
              passportNumberError={passportNumberError}
            />
            {error && (
              <Text style={{ marginTop: 10 }} type="danger">
                {error}
              </Text>
            )}
          </>
        )}
        {isLoggedInAsAdmin && (
          <NotesContainer>
            <InputItem label="Admin notes">
              <TextArea
                value={note}
                onChange={(e): void => {
                  setNote(e.target.value);
                }}
              />
            </InputItem>
          </NotesContainer>
        )}
      </Container>
      {!isVerificationPending && (
        <>
          <Divider />
          <Footer>
            {!isPrimaryUser && !isLoggedInAsAdmin && (
              <Button size="large" type="ghost" onClick={goBack}>
                Back
              </Button>
            )}
            {isLoggedInAsAdmin && onCancel && (
              <Button size="large" type="ghost" onClick={onCancel}>
                Cancel
              </Button>
            )}
            <PrimaryButton
              onClick={onSubmit}
              label="Verify"
              loading={loading}
            />
          </Footer>
        </>
      )}
    </>
  );
};

export default withRouter(UserVerification);
